/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type OrganizationDefaultZoomHostType = typeof OrganizationDefaultZoomHostType[keyof typeof OrganizationDefaultZoomHostType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationDefaultZoomHostType = {
  pool: 'pool',
  interviewer: 'interviewer',
  scheduler: 'scheduler',
  custom: 'custom',
  null: null,
} as const;
