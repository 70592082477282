/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type SchedulingRequestReadCandidateAvailabilitySelectionInterval = typeof SchedulingRequestReadCandidateAvailabilitySelectionInterval[keyof typeof SchedulingRequestReadCandidateAvailabilitySelectionInterval];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SchedulingRequestReadCandidateAvailabilitySelectionInterval = {
  NUMBER_15: 15,
  NUMBER_30: 30,
  NUMBER_60: 60,
  null: null,
} as const;
