/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type OrgmemberInterviewerTagActivityActivityType = typeof OrgmemberInterviewerTagActivityActivityType[keyof typeof OrgmemberInterviewerTagActivityActivityType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrgmemberInterviewerTagActivityActivityType = {
  added: 'added',
  deleted: 'deleted',
  activated: 'activated',
  deactivated: 'deactivated',
} as const;
