/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type InterviewTemplateDefaultSlotType = typeof InterviewTemplateDefaultSlotType[keyof typeof InterviewTemplateDefaultSlotType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterviewTemplateDefaultSlotType = {
  tag: 'tag',
  individual: 'individual',
  null: null,
} as const;
