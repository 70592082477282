import axios from "axios";
import humps from "humps";
import getConfig from "next/config";
import { signOut, getSession } from "next-auth/react";
import qs from "qs";
import { initializeClient as orvalInitClient } from "./orval/mutator/apiClient";
import {
  CALENDAR_INTERGRATION_REQUIRED_CODE,
  GCAL_INTEGRATION_REQUIRED_CODE,
} from "../utils/constants";

interface ApiClientConfig {
  token: string;
}

const { publicRuntimeConfig } = getConfig();

const apiClient = axios.create({
  baseURL: publicRuntimeConfig.API_URL,
  transformResponse: [
    ...(axios.defaults.transformResponse as any),
    (data) => humps.camelizeKeys(data),
  ],
  transformRequest: [
    (data) => humps.decamelizeKeys(data),
    ...(axios.defaults.transformRequest as any),
  ],
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
  },
  headers: { common: { "Content-Type": "application/json" } },
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
});

export const initializeClient = (config: ApiClientConfig) => {
  apiClient.defaults.headers.common = {
    ...apiClient.defaults.headers.common,
    Authorization: `Bearer ${config.token}`,
    "Client-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

const getCurrentToken = () => {
  const header = apiClient.defaults.headers.common.Authorization;
  if (header) {
    return header.toString().split(" ")[1];
  }
  return null;
};

const {
  interceptors: { response },
} = apiClient;

const resetSession = async () => {
  const session = await getSession();
  const accessToken: string = (session as any).accessToken;
  const currentToken = getCurrentToken();
  if (accessToken && currentToken !== accessToken) {
    initializeClient({ token: accessToken });
    orvalInitClient({ token: accessToken });
  } else {
    signOut();
  }
};

response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error.response?.status === 403 || error.response?.status === 401) &&
      error.response.data.message.code !==
        "INSUFFICIENT_CFYI_ROLE_PERMISSION" &&
      error.response?.data.message.code !==
        CALENDAR_INTERGRATION_REQUIRED_CODE &&
      error.response?.data.message.code !== GCAL_INTEGRATION_REQUIRED_CODE
    ) {
      resetSession();
    }
    throw error;
  }
);

export default apiClient;
