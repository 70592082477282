/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type UserScheduleSettingBeforeEventBufferTime = typeof UserScheduleSettingBeforeEventBufferTime[keyof typeof UserScheduleSettingBeforeEventBufferTime];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserScheduleSettingBeforeEventBufferTime = {
  NUMBER_0: 0,
  NUMBER_5: 5,
  NUMBER_10: 10,
  NUMBER_15: 15,
  NUMBER_25: 25,
  NUMBER_30: 30,
  NUMBER_45: 45,
  NUMBER_50: 50,
  NUMBER_55: 55,
  NUMBER_60: 60,
  NUMBER_90: 90,
  NUMBER_120: 120,
  null: null,
} as const;
