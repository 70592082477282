/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type SchedulingRequestRequestType = typeof SchedulingRequestRequestType[keyof typeof SchedulingRequestRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SchedulingRequestRequestType = {
  smart: 'smart',
  self_booking: 'self_booking',
  availability: 'availability',
} as const;
