/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type EmailPurpose = typeof EmailPurpose[keyof typeof EmailPurpose];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmailPurpose = {
  candidate_availability_request: 'candidate_availability_request',
  candidate_self_schedule_request: 'candidate_self_schedule_request',
  interview_confirmation: 'interview_confirmation',
  generic: 'generic',
  interview_reminder: 'interview_reminder',
  null: null,
} as const;
