/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi Internal API
 * Internal API for Candidate.fyi
 * OpenAPI spec version: v1
 */

export type SchedulingAnalyticsEventsActivitiesListActivityType = typeof SchedulingAnalyticsEventsActivitiesListActivityType[keyof typeof SchedulingAnalyticsEventsActivitiesListActivityType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SchedulingAnalyticsEventsActivitiesListActivityType = {
  scheduled: 'scheduled',
  declined: 'declined',
  rescheduled: 'rescheduled',
} as const;
